import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  background-color: #20348a;
  font-family: "SCDream6";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CompanyTitle = styled.div`
  color: #fff;
  font-size: 60px;
`;

const CompanyInfo = styled.div`
  color: #fff;
  font-size: 13px;
  margin-top: -10px;
`;

const CompanyStatus = styled.div`
  color: #fff;
  font-size: 13px;
  margin-top: 12px;
`;

const CommingSoon = () => {
  return (
    <Container>
      <CompanyTitle>TQSOFT</CompanyTitle>
      <CompanyInfo>Software Development &#38; Design</CompanyInfo>
      <CompanyStatus>준비중입니다.</CompanyStatus>
    </Container>
  );
};

export default CommingSoon;
