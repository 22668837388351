import "./App.css";

import CommingSoon from "./components/CommingSoon";

function App() {
  return (
    <div className="App">
      <CommingSoon />
    </div>
  );
}

export default App;
